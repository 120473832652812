import React, { FC, Key, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Box, Button, Dialog, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material'
import { isEmpty, omit, some } from 'lodash'
import { Statsig } from 'statsig-react'

import RentyLogo from '../../assets/imgs/rentyWhiteIcon.svg'
import Logo from '../../assets/imgs/surveyLogo.svg'
import warning from '../../assets/imgs/warning.svg'
import {
  ADD_TOUR_FOR_USER,
  SAVE_PROSPECT_PREFERENCE_TAG,
  SAVE_SCHEDULE_TOUR_SELECT_UNIT,
  SAVE_URL_TTL_LOG,
  TOUR_REASSIGN,
} from '../../graphqls/mutations'
import {
  QUERY_CHECKED_SHELTER,
  QUERY_INIT_TOUR_INFO_FOR_CUSTOMER,
  QUERY_IS_CENTRALIZED_STAGE,
  QUERY_PROPERTY_BY_QUESTIONNAIRE_ID,
  QUERY_PROPERTY_TIME_ZONE,
  QUERY_PROPERTY_TOUR_AGENT,
  QUERY_TOUR_LIST_BY_PROSPECT_ID,
} from '../../graphqls/queries'
import { initPageConfig } from '../../utils'
import { checkIsMobile } from '../../utils/agent'
import { autoAppendS } from '../../utils/formatter'
import ErrorSnackbar from '../errorSnackbar'
import {
  FloorPlanModeUnitType,
  getTourTypeName,
  OtherUnitInfoProps,
  PageTypeType,
  PreferenceList,
  PreSelectedUnitInfo,
  ScheduleTourPropertyType,
  ScheduleTourTourType,
  SelectedUnitInfoProps,
  SelectedUnitsType,
} from './contantsAndType'
import EndStep from './EndStep'
import ScheduleTourDetail from './scheduleTourDetail'
import ScheduleTourFooter from './scheduleTourFooter'
import ScheduleTourFooterPc from './scheduleTourFooterPc'
import ScheduleTourHeader from './scheduleTourHeader'
import ScheduleTourPreference from './scheduleTourPreference'
import ScheduleTourUnit from './scheduleTourUnit'
import ScheduleTourWayAndTime from './scheduleTourWayAndTime'
import SelectedForm from './selectedForm'
import SubmitTourPc from './SubmitTourPc'
import {
  buildTourApartmentsEventMetadata,
  calcSelectedCount,
  calcTimeOverlap,
  fillOnlySelectFloorplanSelectedUnits,
  getFloorPlanName,
  getStatsigProperties,
  refactSelectedUnitTime,
} from './utils'

const MAX_SELECTED_UNIT = 4
let logInterval: NodeJS.Timer | null = null
let errorMsgTimer: NodeJS.Timer
const isMobile = checkIsMobile()
const HEADER_HEIGHT = 64

const ScheduleTour: FC = () => {
  const { questionnaireId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const version = searchParams.get('version')
  const bedroomValue = searchParams.get('bedroomNum') || 'ALL'
  const propertyId = searchParams.get('propertyId') || null
  const [isNoAvailableDay, setIsNoAvailableDay] = useState<boolean | undefined>(undefined)
  const [pageType, setPageType] = useState<PageTypeType>('unit')
  const [selectedPreferences, setSelectedPreferences] = useState<string[]>([])
  const [agentInfo, setAgentInfo] = useState<{ id: string; name: string }>({ id: '', name: '' })
  const [tourTypeList, setTourTypeList] = useState<{ key: string; name: string; desc: string }[]>([])
  const [prospectId, setProspectId] = useState('')
  const [prospectUserName, setProspectUserName] = useState('')
  const [propertyList, setPropertyLsit] = useState<ScheduleTourPropertyType[]>([])
  const [selectedProperty, setselectedProperty] = useState<ScheduleTourPropertyType>()
  const [tourList, setTourList] = useState<ScheduleTourTourType[]>([])
  const [tourType, setTourType] = useState('')
  const [selfGuidTourUnits, setSelfGuidTourUnits] = useState<
    { floorPlanName: string; modelUnitId: number; modelUnitName: string; units: { unitId: number; unitName: string }[] }[]
  >([])
  const [selectedUnits, setSelectedUnits] = useState<SelectedUnitsType>(new Map())
  const [selectedAgentTime, setSelectedAgentTime] = useState<{ time: string; startSecond: string }>()
  const [newAdminUserInfo, setNewAdminUserInfo] = useState<{ newAdminUserId: string; newAdminUserName: string }>()
  const [isAnotherSchedule, setIsAnotherSchedule] = useState(false)

  const [fixedDom, setFixedDom] = useState(false)
  const [selectedPropertyTimeZone, setSelectedPropertyTimeZone] = useState<{ displayText: string; timeZone: string }>({
    displayText: '',
    timeZone: '',
  })
  const [checkUnitTimeDialog, setCheckUnitTimeDialog] = useState(false)
  const [isReschedule, setRescheduleStatus] = useState(false)
  const [scheduledUnitIds, setScheduledUnitIds] = useState([''])
  const [currTourId, setCurrTourId] = useState(0)
  const [isCentralizedStage, setIsCentralizedStage] = useState(false)
  const [openErrorMsg, setOpenErrorMsg] = useState(false)
  const [saveUrlTtlLogApi] = useMutation(SAVE_URL_TTL_LOG, {
    onError() {
      // do nothing
    },
    variables: { input: { questionnaireId, source: 'TOUR' } },
  })
  const [errorMessageOfAddingTour, setErrorMessageOfAddingTour] = useState({
    open: false,
    message: '',
  })
  const [checkedShelter] = useLazyQuery(QUERY_CHECKED_SHELTER, {
    onCompleted({ queryCheckedShelter }) {
      const { data } = queryCheckedShelter || {}
      // 获取第一条数据，查看当前选择的propertyId
      const [shelterChecked] = data || []

      if (!isEmpty(shelterChecked)) {
        if (Array.isArray(propertyList) && propertyList.length > 0) {
          const currentProperty = propertyList?.find(item => item.id === String(shelterChecked.propertyId)) as ScheduleTourPropertyType

          if (!currentProperty) {
            setselectedProperty(propertyList?.[0])
            return
          }
          setselectedProperty(currentProperty)
          // 所有的floorplan
          const { floorPlanModeUnit } = currentProperty || {}
          const allUnits = currentProperty?.floorPlanModeUnit?.map(item => item?.availableUnits?.map(v => v))?.flat()

          // 历史选择的units
          const preFloorPlans = data.reduce(
            (pre: Map<Key, { unitsInfo: SelectedUnitInfoProps; otherUnit: OtherUnitInfoProps }>, unit: PreSelectedUnitInfo) => {
              const floorPlanIndex =
                floorPlanModeUnit?.find(floorPlan => some(floorPlan?.availableUnits, { id: unit?.unitId?.toString() }))?.floorPlanIndex || 0
              const floorPlanName = unit.floorPlanName.replace('Studio - ', '').replace(/^\d+\s\w+\s\d+\s\w+\s-\s/, '')
              const floorPlanKey = `${floorPlanName}-${floorPlanIndex}`
              if (!pre.has(floorPlanKey)) {
                pre.set(floorPlanKey, {
                  unitsInfo: {
                    floorPlanIndex,
                    floorPlanName,
                    floorPlanTitle: floorPlanName,
                    units: [],
                  },
                  otherUnit: {
                    floorPlanIndex: -1,
                    floorPlanName,
                    floorPlanTitle: floorPlanName,
                    name: '',
                  },
                })
              }

              if (unit.unitId) {
                const currUnit = allUnits?.find(item => Number(item?.id) === Number(unit.unitId))
                pre.get(floorPlanKey)?.unitsInfo.units.push({
                  id: unit.unitId,
                  name: currUnit?.name || '',
                  availableStatus: 0,
                  availableStartTime: 0,
                  preTourStartDate: 0,
                  preTourEndDate: 0,
                })
              }

              return pre
            },
            new Map(),
          )
          setSelectedUnits(preFloorPlans)
        }
      } else {
        setselectedProperty(propertyList?.[0])
      }
    },
  })

  const [saveProspectPreferenceTag] = useMutation(SAVE_PROSPECT_PREFERENCE_TAG)

  const [queryPropertyTourAgentApi] = useLazyQuery(QUERY_PROPERTY_TOUR_AGENT, {
    onCompleted: agentListData => {
      const { queryPropertyTourAgent = {} } = agentListData || {}
      const { adminUsers = [] } = queryPropertyTourAgent || {}
      const { adminUserId, adminUserName } = adminUsers[0] || {}
      setAgentInfo({ id: adminUserId, name: adminUserName })
    },
    onError() {
      // nothing
    },
  })
  const [queryInitTourInfoForCustomerApi] = useLazyQuery(QUERY_INIT_TOUR_INFO_FOR_CUSTOMER, {
    onCompleted({ queryInitTourInfoForCustomer }) {
      const { data } = queryInitTourInfoForCustomer || {}
      const { tourType: tourTypeResult, selfGuidedUnit = [], prospectFirstName = '', prospectLastName = '' } = data || {}
      setProspectUserName(`${prospectFirstName} ${prospectLastName}`)
      setTourTypeList(tourTypeResult || [])
      setTourType((tourTypeResult || [])[0]?.key ?? '')
      setSelfGuidTourUnits(selfGuidedUnit)
    },
    onError() {
      // nothing
    },
  })
  const [queryPropertyTimeZoneApi] = useLazyQuery(QUERY_PROPERTY_TIME_ZONE, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const { queryPropertyTimeZone } = data || {}
      const { displayText, timeZone } = queryPropertyTimeZone || {}
      setSelectedPropertyTimeZone({ displayText, timeZone })
    },
    onError() {
      setSelectedPropertyTimeZone({ displayText: '', timeZone: '' })
    },
  })

  const [queryTourListByProspectIdApi, { loading: tourListLoading }] = useLazyQuery(QUERY_TOUR_LIST_BY_PROSPECT_ID, {
    onCompleted(res) {
      const { queryTourListByProspectId } = res || {}
      const { data } = queryTourListByProspectId || {}
      const curTourList = (Array.isArray(data) ? data : []).filter(item => item.status !== 'CANCELED')
      setTourList(curTourList)
      if (curTourList.some(tour => selectedProperty && String(tour.propertyId) === String(selectedProperty.id))) {
        Statsig.logEvent('schedule_tour_tour_detail', window.location.href, {
          pageType,
          questionnaireId,
          prospectId,
          propertyId: selectedProperty?.id || '',
        })
        if (!isAnotherSchedule) {
          setPageType('tourInfo')
        }
      }
    },
    onError() {
      // something bad
    },
  })

  const { loading, refetch } = useQuery(QUERY_PROPERTY_BY_QUESTIONNAIRE_ID, {
    nextFetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      questionnaireId,
      propertyId: Number(propertyId),
    },
    async onCompleted(data) {
      const { searchPropertyByQuestionnaireId = {} } = data || {}
      if (!searchPropertyByQuestionnaireId) {
        return
      }
      const { propertyResult: properties, prospectId: currentProspectId } = searchPropertyByQuestionnaireId || {}
      const propertyResult = refactSelectedUnitTime(properties)
      setProspectId(currentProspectId || '')
      setPropertyLsit(Array.isArray(propertyResult) ? propertyResult : [])

      checkedShelter({
        variables: {
          prospectId: Number(currentProspectId),
        },
      })
      Statsig.logEvent('schedule_tour_recommend_property', window.location.href, {
        questionnaireId,
        prospectId: currentProspectId,
        properties: JSON.stringify(getStatsigProperties(propertyResult)),
      })

      if (!isReschedule) {
        queryTourListByProspectIdApi({
          variables: { prospectId: currentProspectId, propertyId: Number(propertyId) || Number(propertyResult?.[0]?.id) },
        })
      }

      if (Array.isArray(propertyResult) && propertyResult?.length) {
        const currentProperty = propertyResult[0] as ScheduleTourPropertyType
        const map: SelectedUnitsType = new Map()
        currentProperty?.floorPlanModeUnit?.forEach(floor => {
          const defaultSelectedUnits = floor.availableUnits
            //  scheduledUnitIds: The unitIds has been selected after the second entry.
            .filter(unit => unit.intention || scheduledUnitIds.includes(unit.id))
            .map(unit => ({
              name: unit.name,
              id: Number(unit.id),
              availableStatus: unit.availableStatus,
              availableStartTime: unit.availableStartTime,
              preTourStartDate: unit.preTourStartDate,
              preTourEndDate: unit.preTourEndDate,
            }))
          if (defaultSelectedUnits.length > 0) {
            let floorPlanTitle = ''
            const planName = floor.floorPlanName ? ` - ${floor.floorPlanName}` : ''
            if (Number(floor?.floorPlanBedroomNum) === 0) {
              floorPlanTitle = `Studio${planName}`
            }
            floorPlanTitle = `${Number(floor?.floorPlanBedroomNum)} Bed ${Number(floor?.floorPlanBathroomNum)} Bath${planName}`
            map.set(`${floor.floorPlanName}-${floor.floorPlanIndex}`, {
              unitsInfo: {
                floorPlanTitle,
                floorPlanIndex: floor.floorPlanIndex,
                floorPlanName: floor.floorPlanName,
                units: defaultSelectedUnits,
              },
              otherUnit: { floorPlanTitle, floorPlanIndex: -1, floorPlanName: '', name: '' },
            })
          }
        })

        if (map.size > 0) {
          setSelectedUnits(map)
        }
      }
      if (Array.isArray(propertyResult) && propertyResult.length > 0 && currentProspectId && !isReschedule) {
        Statsig.logEvent('schedule_tour_default_select_property', window.location.href, {
          questionnaireId,
          pageType,
          prospectId: currentProspectId,
          propertyId: propertyResult?.[0].id,
        })
        const variables = { propertyId: Number(propertyResult?.[0]?.id), prospectId: currentProspectId }
        const propertyTimeZoneResponse = await queryPropertyTimeZoneApi({
          variables: {
            input: {
              propertyId: Number(propertyResult?.[0].id),
              prospectId: currentProspectId,
            },
          },
        })
        const { isCentralizedLeasing: isCentralizedStage } = propertyTimeZoneResponse?.data?.queryPropertyTimeZone || {}
        setIsCentralizedStage(isCentralizedStage)

        if (!isCentralizedStage) {
          queryPropertyTourAgentApi({
            variables,
          })
        }
        queryInitTourInfoForCustomerApi({
          variables,
        })
      }
    },
    onError() {
      Statsig.logEvent('schedule_tour_query_property_error', window.location.href, { questionnaireId })
      // something bad
    },
  })
  const [addTourForUserApi, { loading: addTourForUserLoading }] = useMutation(ADD_TOUR_FOR_USER, {
    async onCompleted(data) {
      const { addTourForUser } = data || {}
      const { ret, tourId, msg } = addTourForUser || {}
      if (ret && !!tourId) {
        await queryTourListByProspectIdApi({ variables: { prospectId }, fetchPolicy: 'network-only' }).catch()
        setPageType('tourInfo')
      } else {
        setErrorMessageOfAddingTour(() => ({
          open: true,
          message: msg,
        }))
      }
    },
    onError() {
      // do nothing
    },
  })

  const [tourReassign, { loading: tourReassignLoading }] = useMutation(TOUR_REASSIGN, {
    async onCompleted(data) {
      const { tourReassign } = data || {}
      const { ret } = tourReassign || {}
      if (ret) {
        await queryTourListByProspectIdApi({ variables: { prospectId }, fetchPolicy: 'network-only' }).catch()
        setPageType('tourInfo')
        setIsAnotherSchedule(false)
      }
    },
    onError() {
      // do nothing
    },
  })

  const [saveLeasingWantTour] = useMutation(SAVE_SCHEDULE_TOUR_SELECT_UNIT, {
    onError() {
      // nothing to do
    },
  })

  const havePerference = useMemo(() => {
    let result = false
    selectedUnits.forEach(item => {
      const onlyFloorPlan = !!item.unitsInfo.floorPlanName && item.unitsInfo.units.length === 0
      const onlyOtherUnitChecked = item?.otherUnit?.floorPlanIndex !== -1 && !item?.otherUnit?.name
      result = result || onlyFloorPlan || onlyOtherUnitChecked
    })
    setSelectedPreferences([])
    return result
  }, [selectedUnits])
  const headerList = useMemo(() => propertyList.map(item => ({ id: item.id, name: item.aliasName || item.name })), [propertyList])
  const selectedCount = useMemo(() => calcSelectedCount(selectedUnits), [selectedUnits])
  const disableNext = useMemo(() => {
    if (pageType === 'unit') return selectedCount === 0
    if (pageType === 'preference') return false // preference 选填
    if (pageType === 'submitTour') return tourType === '' || !selectedAgentTime?.startSecond
    return true
  }, [pageType, selectedPreferences, tourType, selectedCount, selectedAgentTime])

  const checkSelectedUnitTime = () => {
    const { isOverlap, min, max } = calcTimeOverlap(fillOnlySelectFloorplanSelectedUnits(selectedUnits, selectedProperty))
    const selectValueObj = [...selectedUnits.entries()].reduce((obj, [key, value]) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = value
      return obj
    }, {} as Record<string, unknown>)
    if (!isOverlap) {
      Statsig.logEvent('schedule_select_units_time_overlap', window.location.href, {
        isOverlap: isOverlap.toString(),
        min: Number(min).toString(),
        max: Number(max).toString(),
        selectValueObj: JSON.stringify(selectValueObj),
        prospectId,
        propertyId: selectedProperty?.id ?? '',
      })
      setCheckUnitTimeDialog(true)
      return
    }
    if (!selectedAgentTime) return
    if (min > Number(selectedAgentTime.startSecond) * 1000 || max < Number(selectedAgentTime.startSecond) * 1000) {
      Statsig.logEvent('schedule_select_units_time_overlap', window.location.href, {
        isOverlap: isOverlap.toString(),
        min: Number(min).toString(),
        max: Number(max).toString(),
        selectValueObj: JSON.stringify(selectValueObj),
        selectedAgentTime: selectedAgentTime.startSecond,
        prospectId,
        propertyId: selectedProperty?.id ?? '',
      })
      setCheckUnitTimeDialog(true)
    } else {
      onSubmitTour()
    }
  }

  const afterCloseCheckUnitTimeDialog = (submit: boolean) => {
    Statsig.logEvent('schedule_units_time_dialog', window.location.href, {
      submit: submit.toString(),
      prospectId,
      propertyId: selectedProperty?.id ?? '',
    })
    setCheckUnitTimeDialog(false)
    if (submit) {
      onSubmitTour()
    }
  }

  const onFooterNext = useCallback(() => {
    let nextType: PageTypeType = pageType
    let logs = {
      pageType,
      questionnaireId,
      prospectId: selectedProperty?.id || '',
    }
    const selectValueObj = [...selectedUnits.entries()].reduce((obj, [key, value]) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = value
      return obj
    }, {} as Record<string, unknown>)

    Statsig.logEvent(
      'schedule_tour_recommendation_apartments',
      window.location.href,
      buildTourApartmentsEventMetadata(prospectId, selectedProperty, selectedUnits) as Record<string, string>,
    )

    if (pageType === 'unit') {
      nextType = havePerference ? 'preference' : 'submitTour'
      saveSelectedUnitsToLeasing(selectedUnits)
      logs = Object.assign(logs, { units: selectValueObj, nextType })
      if (!isMobile) {
        // pc allow link submitTour
        nextType = 'submitTour'
      }
      setPageType(nextType)
      Statsig.logEvent('schedule_tour_footer_next', window.location.href, logs)
    }
    if (pageType === 'preference') {
      setSelectedAgentTime(undefined)
      nextType = 'submitTour'
      logs = Object.assign(logs, { units: selectValueObj, nextType, preferences: selectedPreferences })
      Statsig.logEvent('schedule_tour_footer_next', window.location.href, logs)
      setPageType(nextType)
    }
    if (pageType === 'submitTour') {
      checkSelectedUnitTime()
    }
  }, [prospectId, selectedProperty, selectedUnits, pageType, selectedAgentTime, isCentralizedStage])

  const onFooterBack = useCallback(() => {
    let nextType: PageTypeType = pageType
    setNewAdminUserInfo({ newAdminUserId: '', newAdminUserName: '' })
    if (pageType === 'submitTour' && isReschedule) {
      setPageType('tourInfo')
      setIsAnotherSchedule(false)
      return
    }

    if (pageType === 'submitTour') {
      setSelectedAgentTime(undefined)
      nextType = havePerference ? 'preference' : 'unit'
    }
    if (pageType === 'preference') {
      nextType = 'unit'
    }
    Statsig.logEvent('schedule_tour_footer_back', window.location.href, {
      pageType,
      nextType,
      questionnaireId,
      propertyId: selectedProperty?.id || '',
      prospectId,
    })
    if (!isMobile) {
      // pc allow link unit
      nextType = 'unit'
    }
    setPageType(nextType)
  }, [havePerference, pageType])
  const onSubmitTour = () => {
    if ((!isCentralizedStage && !agentInfo?.id) || !selectedProperty || !selectedAgentTime || !selectedPropertyTimeZone?.timeZone) {
      return
    }
    const otherUnit = [...selectedUnits.values()]
      .map(item => item?.otherUnit)
      .filter(item => item?.floorPlanIndex !== -1)
      .map(item => omit(item, ['floorPlanIndex']))
      ?.map(item => ({ floorPlanName: item?.floorPlanName, name: item?.name }))
    const unitsInfo = [...selectedUnits.values()].map(item => {
      const units = item?.unitsInfo?.units?.map(i => i.id)
      return { floorPlanName: item?.unitsInfo?.floorPlanName, units }
    })
    const input = {
      prospectId: Number(prospectId),
      propertyId: Number(selectedProperty.id),
      tourAgentId: newAdminUserInfo?.newAdminUserId, // agentInfo.id,
      tourAgentName: newAdminUserInfo?.newAdminUserName,
      time: selectedAgentTime?.time,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timeStartSecond: Number(selectedAgentTime?.startSecond),
      propertyTimeZone: selectedPropertyTimeZone?.timeZone,
      tourType,
      preference: selectedPreferences,
      otherUnit,
      unitsInfo,
      questionnaireTourDetailURL: window.location.href,
    }

    const reScheduleInput = {
      id: currTourId,
      adminUserId: newAdminUserInfo?.newAdminUserId,
      time: selectedAgentTime?.time,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      propertyTimeZone: selectedPropertyTimeZone?.timeZone,
      timeStartSecond: Number(selectedAgentTime?.startSecond),
      source: 'USER',
    }
    const preferenceTagList: { mainTag: { tagCode: string; tagName: string } }[] = []
    selectedPreferences?.forEach(item => {
      const findItem = PreferenceList?.find(v => v?.label === item)
      if (findItem) {
        preferenceTagList.push({
          mainTag: {
            tagCode: findItem?.value,
            tagName: findItem?.label,
          },
        })
      }
    })

    saveProspectPreferenceTag({
      variables: {
        input: {
          prospectId: Number(prospectId),
          tagList: preferenceTagList,
        },
      },
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Statsig.logEvent('schedule_tour_submit_tour', window.location.href, input as any)

    if (isReschedule && currTourId) {
      tourReassign({
        variables: {
          input: reScheduleInput,
        },
      }).finally(() => {
        if (logInterval) {
          clearInterval(logInterval)
        }
      })
    } else {
      addTourForUserApi({
        variables: {
          input,
        },
      }).finally(() => {
        if (logInterval) {
          clearInterval(logInterval)
        }
      })
    }
  }
  const saveSelectedUnitsToLeasing = (map: SelectedUnitsType) => {
    if (!selectedProperty) return

    const aiLeasingWantTourList: unknown[] = []

    map.forEach(item => {
      if (item.unitsInfo.floorPlanIndex !== -1) {
        const tourListJson = item.unitsInfo.units.map(unit => ({
          propertyId: Number(selectedProperty.id),
          propertyName: selectedProperty.name,
          unitId: unit.id,
          unitName: unit.name,
        }))
        aiLeasingWantTourList.push({
          prospectId: Number(prospectId),
          propertyId: Number(selectedProperty.id),
          floorPlanName: item.unitsInfo.floorPlanTitle,
          tourListJson,
        })
      }
    })

    if (aiLeasingWantTourList.length === 0) return
    const input = { aiLeasingWantTourList }
    localStorage.setItem('saveReportUrlCannotSelectTourTimeInput', JSON.stringify(input))
    saveLeasingWantTour({
      variables: {
        input,
      },
    })
  }
  const changeProperty = async (property: ScheduleTourPropertyType) => {
    if (!property) return
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    setSelectedAgentTime(undefined)
    setAgentInfo({ id: '', name: '' })
    setSelectedPreferences([])
    setSelectedUnits(new Map())
    setTourType('')
    setselectedProperty(property)
    const propertyTimeZoneResponse = await queryPropertyTimeZoneApi({
      variables: {
        input: {
          propertyId: Number(property.id),
          prospectId,
        },
      },
    })
    const { isCentralizedLeasing: isCentralizedStage } = propertyTimeZoneResponse?.data?.queryPropertyTimeZone || {}
    setIsCentralizedStage(isCentralizedStage)
    const variables = { propertyId: Number(property.id), prospectId }
    queryInitTourInfoForCustomerApi({ variables })
    if (!isCentralizedStage) {
      queryPropertyTourAgentApi({
        variables,
      })
    }

    if (tourList.some(tour => property && String(tour.propertyId) === String(property.id))) {
      setPageType('tourInfo')
      setIsAnotherSchedule(false)
    } else {
      setPageType('unit')
    }
  }
  const checkLogInterval = () => {
    if (document.visibilityState === 'hidden' && logInterval) {
      clearInterval(logInterval)
    }
    if (document.visibilityState === 'visible') {
      saveUrlTtlLogApi()
      if (logInterval) clearInterval(logInterval)
      logInterval = setInterval(saveUrlTtlLogApi, 10000)
    }
  }
  const onPropertyChange = (id: string) => {
    const target = propertyList.find(item => item.id === id)
    if (!target) return
    changeProperty(target)
  }
  const onSelectUnitChange = (map: SelectedUnitsType) => {
    const changeCount = calcSelectedCount(map)
    if (changeCount <= MAX_SELECTED_UNIT) {
      const selectValueObj = [...map.entries()].reduce((obj, [key, value]) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = value
        return obj
      }, {} as Record<string, unknown>)
      Statsig.logEvent('schedule_tour_select_unit', window.location.href, {
        questionnaireId,
        prospectId,
        selectUnits: JSON.stringify(selectValueObj),
        pageType,
      })
      setSelectedUnits(map)
    } else {
      setOpenErrorMsg(true)
      clearTimeout(errorMsgTimer)
      errorMsgTimer = setTimeout(() => {
        setOpenErrorMsg(false)
      }, 2000)
    }
  }

  const onClear = () => {
    setSelectedUnits(new Map())
  }

  const onSearchUnits = (event: MouseEvent<HTMLButtonElement>) => {
    const {
      currentTarget: {
        dataset: { bd },
      },
    } = event

    setSearchParams((prevSearchParams: string | string[][] | Record<string, string> | URLSearchParams | undefined) => {
      const newSearchParams = new URLSearchParams(prevSearchParams)
      newSearchParams.set('bedroomNum', bd || '')

      return newSearchParams
    })
  }

  const filterOptions = useMemo(() => {
    let options = [
      {
        label: 'All',
        key: 'ALL',
        value: 'ALL',
      },
    ]
    if (selectedProperty?.id) {
      const optionsMap = selectedProperty.floorPlanModeUnit.reduce((pre, floorPlan) => {
        if (!pre.has(floorPlan.floorPlanBedroomNum)) {
          const beds = parseInt(floorPlan.floorPlanBedroomNum, 10)
          const option = {
            label: parseInt(floorPlan.floorPlanBedroomNum, 10) === 0 ? 'Studio' : `${beds} ${autoAppendS('bed', beds)}`,
            value: floorPlan.floorPlanBedroomNum,
            key: `BD_${floorPlan.floorPlanBedroomNum}`,
          }

          pre.set(floorPlan.floorPlanBedroomNum, option)
        }

        return pre
      }, new Map())
      options = options.concat(Array.from(optionsMap.values()))
    }

    return options
  }, [selectedProperty?.id])

  const floorPlans = useMemo(() => {
    if (!selectedProperty?.id) {
      return []
    }

    const { floorPlanModeUnit } = selectedProperty
    const floorPlanList =
      bedroomValue === 'ALL' ? floorPlanModeUnit : floorPlanModeUnit.filter(floorPlan => floorPlan.floorPlanBedroomNum === bedroomValue)

    return floorPlanList.map(item => ({
      ...item,
      floorPlanName: item.floorPlanName || getFloorPlanName(item),
    }))
  }, [bedroomValue, selectedProperty])

  useEffect(() => {
    if (Number(version)) {
      initPageConfig(Number(version))
    }
    if (logInterval) {
      clearInterval(logInterval)
    }
    saveUrlTtlLogApi()
    logInterval = setInterval(saveUrlTtlLogApi, 10000)
    document.addEventListener('visibilitychange', checkLogInterval)
    Statsig.logEvent('schedule_tour_PV_UV', window.location.href, {
      questionnaireId,
      propertyId: selectedProperty?.id || '',
    })
    return () => {
      if (logInterval) clearInterval(logInterval)
      document.removeEventListener('visibilitychange', checkLogInterval)
    }
  }, [])
  const onWinScroll = () => {
    setFixedDom(window.scrollY > HEADER_HEIGHT)
  }
  const onBackNewUserId = (data: { newAdminUserId: string; newAdminUserName: string }) => {
    setNewAdminUserInfo(data)
    setAgentInfo({ id: data?.newAdminUserId, name: data?.newAdminUserName })
  }
  const onAnotherCallback = () => {
    setIsAnotherSchedule(true)
  }

  useEffect(() => {
    window.addEventListener('scroll', onWinScroll)
    return () => {
      window.removeEventListener('scroll', onWinScroll)
    }
  }, [])

  useEffect(() => {
    if (scheduledUnitIds || isAnotherSchedule) {
      refetch()
    }
  }, [scheduledUnitIds, isAnotherSchedule])

  if (!questionnaireId) return null
  if (loading || tourListLoading) {
    return (
      <>
        <Skeleton variant="rectangular" height={240} />
        <Skeleton height={200} className="w-[calc(100%-40px)] m-auto" />
        <Skeleton variant="rectangular" className="w-full fixed bottom-0 !h-[90px]" />
      </>
    )
  }

  const getFooter = () =>
    isMobile ? (
      <ScheduleTourFooter
        onBack={onFooterBack}
        onClick={onFooterNext}
        disabled={disableNext || tourListLoading || addTourForUserLoading || tourReassignLoading}
        type={pageType}
        nextLoading={tourListLoading || addTourForUserLoading || tourReassignLoading}
        isReschedule={isReschedule}
        selectedCount={selectedCount}
        maxUnit={MAX_SELECTED_UNIT}
      />
    ) : (
      <ScheduleTourFooterPc
        onBack={onFooterBack}
        onClick={onFooterNext}
        disabled={disableNext || tourListLoading || addTourForUserLoading || tourReassignLoading}
        type={pageType}
        nextLoading={tourListLoading || addTourForUserLoading || tourReassignLoading}
        isReschedule={isReschedule}
      />
    )

  const body = (
    <div className="w-full  min-h-screen lg:min-h-[calc(100vh-64px)] lg:w-1/2  m-auto bg-[white]">
      {pageType === 'unit' && (
        <div className="lg:min-h-[calc(100vh-125px)]">
          <div
            className="w-full h-48 lg:h-[261px] bg-gray-200 bg-no-repeat bg-cover"
            style={{
              backgroundImage: selectedProperty
                ? `linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),url('${selectedProperty.headPicUrl}')`
                : '',
            }}
          />
          <div
            className="w-[calc(100%-40px)]  lg:max-w-[50%] m-auto mt-[-40px] px-[22px] py-4 rounded-t-xl"
            style={{ background: 'linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 100%)' }}
          >
            {fixedDom ? <div className="lg:w-full w-1/2 h-6 bg-white lg:block hidden" /> : null}
            <ScheduleTourHeader
              fixedDom={fixedDom}
              theme="black"
              pageType={pageType}
              list={propertyList}
              showMenu={headerList.length > 1}
              onChange={onPropertyChange}
              selected={selectedProperty?.id ?? ''}
            />
            {selectedProperty?.address && (
              <Typography className="!font-Averta4 !text-sm !text-center">{selectedProperty.address}</Typography>
            )}
          </div>
          <Box className="py-[13px] px-5 lg:py-6 lg:px-10">
            <div className="w-full py-[13px] px-5 lg:py-6 lg:px-10 flex items-center justify-between lg:relative top-0 bg-white z-1 lg:z-1">
              <Typography className="!font-Averta6 !text-base">Select your preferred floor plans</Typography>
              <div className="flex items-center font-averta ">
                <span className="mr-2.5 text-sm font-[400]">
                  {selectedCount}/{MAX_SELECTED_UNIT}
                </span>
                <button onClick={onClear} type="button" className="underline text-sm font-bold">
                  Clear
                </button>
              </div>
            </div>
            <Box className="w-full overflow-x-auto pb-1.5">
              <Stack direction="row" spacing={1}>
                {filterOptions.map(filter => (
                  <Button
                    key={filter.key}
                    className="!font-Averta6 !text-xs"
                    onClick={onSearchUnits}
                    data-bd={filter.value}
                    sx={{
                      border: `1px solid ${filter.value === bedroomValue ? '#050A22' : '#E0E2EF'}`,
                      color: '#050A22',
                      borderRadius: '8px',
                      padding: '8px 16px',
                      minWidth: '100px',
                      textTransform: 'none',
                    }}
                  >
                    {filter.label}
                  </Button>
                ))}
              </Stack>
            </Box>
          </Box>
          <div className="w-full pb-[95px]">
            <ScheduleTourUnit
              questionnaireId={questionnaireId}
              selectedUnits={selectedUnits}
              propertyId={selectedProperty?.id ?? ''}
              onchange={onSelectUnitChange}
              floorPlans={floorPlans}
            />
          </div>
        </div>
      )}
      {pageType === 'preference' && (
        <>
          <div className="fixed top-0 w-full lg:max-w-1/2 z-10 bg-white">
            {fixedDom ? <div className="lg:w-full w-1/2 h-6 bg-white lg:block hidden" /> : null}
            <ScheduleTourHeader
              fixedDom={fixedDom}
              pageType={pageType}
              list={propertyList}
              selected={selectedProperty?.id ?? ''}
              theme="black"
              showBack
              showMenu={false}
              onBack={onFooterBack}
            />
          </div>
          <div className="pt-[52px] pb-[95px]">
            <SelectedForm
              selectedUnits={selectedUnits}
              filledSelectedUnits={fillOnlySelectFloorplanSelectedUnits(selectedUnits, selectedProperty)}
              property={{
                name: selectedProperty ? selectedProperty.aliasName || selectedProperty.name : 'Unknown',
                address: selectedProperty?.address ?? 'unknown',
              }}
            />
            <ScheduleTourPreference selectedPreferences={selectedPreferences} onChange={setSelectedPreferences} />
          </div>
        </>
      )}
      {pageType === 'submitTour' && (
        <>
          {fixedDom ? <div className="lg:w-full w-1/2 h-6 bg-white lg:block hidden" /> : null}
          <div className={`${fixedDom ? 'lg:sticky lg:max-w-full' : 'lg:relative'} fixed top-0 w-full z-10 bg-white`}>
            <ScheduleTourHeader
              fixedDom={fixedDom}
              pageType={pageType}
              list={propertyList}
              selected={selectedProperty?.id ?? ''}
              theme="black"
              showBack={!isReschedule}
              showMenu={false}
              title={`${isReschedule ? 'Reschedule Tour' : ''}`}
              onBack={onFooterBack}
            />
          </div>
          {isMobile ? (
            <div className="pt-[52px] pb-[95px]">
              <SelectedForm
                selectedUnits={selectedUnits}
                filledSelectedUnits={fillOnlySelectFloorplanSelectedUnits(selectedUnits, selectedProperty)}
                property={{
                  name: selectedProperty ? selectedProperty.aliasName || selectedProperty.name : 'Unknown',
                  address: selectedProperty?.address ?? 'unknown',
                }}
                preference={selectedPreferences}
                tourOption={isReschedule ? getTourTypeName(tourType) : ''}
              />
              <ScheduleTourWayAndTime
                isCentralizedStage={isCentralizedStage}
                selfGuidTourUnits={selfGuidTourUnits}
                typeList={tourTypeList}
                type={tourType}
                prospectId={prospectId}
                timeZoneInfo={selectedPropertyTimeZone}
                selectedUnits={selectedUnits}
                selectedProperty={selectedProperty}
                onChangeType={setTourType}
                agentInfo={agentInfo}
                isReschedule={isReschedule}
                onChangeTime={setSelectedAgentTime}
                onBackNewUserId={onBackNewUserId}
                onNoAvailableDay={() => setIsNoAvailableDay(true)}
              />
              <ErrorSnackbar setAddTourError={setErrorMessageOfAddingTour} addTourError={errorMessageOfAddingTour} />
            </div>
          ) : (
            <SubmitTourPc
              property={{
                name: selectedProperty ? selectedProperty.aliasName || selectedProperty.name : 'Unknown',
                address: selectedProperty?.address ?? 'unknown',
                propertyId: selectedProperty?.id ?? '',
              }}
              isCentralizedStage={isCentralizedStage}
              selfGuidTourUnits={selfGuidTourUnits}
              prospectId={prospectId}
              selectedProperty={selectedProperty}
              havePerference={havePerference}
              setSelectedPreferences={setSelectedPreferences}
              preference={selectedPreferences}
              onChangeTime={setSelectedAgentTime}
              agentInfo={agentInfo}
              onChangeType={setTourType}
              selectedUnits={selectedUnits}
              timeZoneInfo={selectedPropertyTimeZone}
              type={tourType}
              typeList={tourTypeList}
              setPageType={setPageType}
              isReschedule={isReschedule}
              onBackNewUserId={onBackNewUserId}
              onNoAvailableDay={() => setIsNoAvailableDay(true)}
              setAddTourError={setErrorMessageOfAddingTour}
              addTourError={errorMessageOfAddingTour}
            />
          )}
        </>
      )}
      {pageType !== 'tourInfo' ? getFooter() : null}
      {pageType === 'tourInfo' && (
        <>
          {fixedDom ? <div className="lg:w-full w-1/2 h-6 bg-white lg:block hidden" /> : null}
          <div className="fixed lg:relative  top-0 w-full z-10 bg-white">
            <ScheduleTourHeader
              fixedDom={fixedDom}
              pageType={pageType}
              list={propertyList}
              selected={selectedProperty?.id ?? ''}
              theme="black"
              showBack={false}
              showMenu={headerList.length > 1}
              onChange={onPropertyChange}
            />
          </div>
          <div className="pt-[52px] pb-[95px]">
            <ScheduleTourDetail
              onClickCard={onPropertyChange}
              tourList={tourList}
              propertyId={selectedProperty?.id ?? ''}
              prospectUserName={prospectUserName}
              propertyList={propertyList}
              selectedUnits={selectedUnits}
              property={{
                name: selectedProperty ? selectedProperty.aliasName || selectedProperty.name : 'Unknown',
                address: selectedProperty?.address ?? 'unknown',
              }}
              onAnotherCallback={onAnotherCallback}
              selectedProperty={selectedProperty}
              preference={selectedPreferences}
              setPageType={setPageType}
              setRescheduleStatus={setRescheduleStatus}
              setCurrTourId={setCurrTourId}
              prospectId={prospectId}
              setScheduledUnitIds={setScheduledUnitIds}
            />
          </div>
        </>
      )}
      <Dialog open={checkUnitTimeDialog} onClose={() => afterCloseCheckUnitTimeDialog(false)}>
        <Box className="!p-5 lg:max-w-[335px]">
          <Typography className="!text-[#050A22] !text-base !font-Averta4 !mb-12">
            Some of the units you&apos;re interested in won&apos;t be available during the selected tour time. Would you like to continue
            scheduling?
          </Typography>
          <Box className="flex justify-between">
            <Box
              onClick={() => afterCloseCheckUnitTimeDialog(true)}
              className="!text-[#050A22] !px-[17px] !py-2 !border !border-[#050A22] !rounded-xl !font-Averta6 !text-base !capitalize"
            >
              Schedule
            </Box>
            <Box
              onClick={() => afterCloseCheckUnitTimeDialog(false)}
              className="!text-white !bg-[#E74F4F] !px-[17px] !py-2 !rounded-xl !font-Averta6 !text-base !capitalize"
            >
              Update
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  )
  return (
    <div className="w-full bg-[#F7F9FC] min-h-screen">
      {!isMobile && (
        <>
          <div className="bg-[#111212] sticky z-20 top-0  px-10 h-16 flex items-center justify-start">
            <img src={Number(version) ? RentyLogo : Logo} className="h-6" alt="tripalink" />
            <div className="bg-[white] mx-8 h-5 w-[1px] border-box" />
            <Typography className="!font-Averta6 !text-lg !text-[white]">Schedule Tour</Typography>
          </div>
          {openErrorMsg && (
            <div className="fixed m-auto z-40 -translate-x-1/2 left-[50%] top-[74px] text-[white] text-sm font-Averta4 rounded bg-[rgba(13,19,51,0.8)] px-4 py-2.5 flex items-center">
              <img src={warning} alt="" className="mr-1" />
              <span>You can only select up to 4 for your tour</span>
            </div>
          )}
        </>
      )}
      {isNoAvailableDay === true ? <EndStep /> : body}
    </div>
  )
}

export default ScheduleTour

import React, { useEffect } from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { useMutation } from '@apollo/client'
import { REPORT_URL_CANNOT_SELECT_TOUR_TIME } from '../../graphqls/mutations'
import NoUnitImg from '../../assets/imgs/noUnit.png'

export default function EndStep() {
  const isPCUI = useMediaQuery('(min-width:512px)')

  const [reportUrlCannotSelectTourTime] = useMutation(REPORT_URL_CANNOT_SELECT_TOUR_TIME)
  useEffect(() => {
    const params = JSON.parse(localStorage.getItem('saveReportUrlCannotSelectTourTimeInput') || '{}')
    reportUrlCannotSelectTourTime({
      variables: {
        input: params,
      },
    })
  }, [])
  return (
    <Box className="w-full !px-5 !py-[88px] flex flex-col justify-center items-center">
      {' '}
      <img className="w-20 h-20" src={NoUnitImg} alt="no unit" />
      <Typography className={`!mt-4 !font-Averta6 ${isPCUI ? '!text-2xl' : '!text-lg'}`}>Got it, thanks!</Typography>
      <Typography className={`!mt-2 !font-Averta4 ${isPCUI ? '!text-base' : '!text-sm'} !text-[#75798D]`}>
        Our leasing team will contact you soon.
      </Typography>
    </Box>
  )
}

import { useState } from 'react'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { Statsig } from 'statsig-react'

import { AppleCalendarIcon, GoogleCalendarIcon, ICalendarIcon, OutlookCalendarIcon } from '../../assets/icons/calendar'
import { ScheduleTourTourType } from './contantsAndType'

interface CalendarEvent {
  title: string
  description: string
  location: string
  startTime: string
  endTime: string
  timezone: string
}

function generateGoogleCalendarUrl(event: CalendarEvent) {
  const params = new URLSearchParams({
    action: 'TEMPLATE',
    text: event.title,
    details: event.description,
    location: event.location,
    dates: `${moment(event.startTime).format('YYYYMMDD[T]HHmmss')}/${moment(event.endTime).format('YYYYMMDD[T]HHmmss')}`,
    ctz: event.timezone,
  })
  return `https://calendar.google.com/calendar/render?${params.toString()}`
}

function generateOutlookOnlineUrl(event: CalendarEvent) {
  const params = new URLSearchParams({
    path: '/calendar/action/compose',
    rru: 'addevent',
    subject: event.title,
    body: event.description,
    location: event.location,
    startdt: moment(event.startTime).format('YYYY-MM-DDTHH:mm:ss'),
    enddt: moment(event.endTime).format('YYYY-MM-DDTHH:mm:ss'),
  })
  return `https://outlook.live.com/calendar/0/${params.toString()}`
}

function generateIcsFileContent(event: CalendarEvent) {
  return `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART:${moment(event.startTime).format('YYYYMMDDTHHmmss')}
DTEND:${moment(event.endTime).format('YYYYMMDDTHHmmss')}
SUMMARY:${event.title}
DESCRIPTION:${event.description.replace(/\n/g, '\\n')}
LOCATION:${event.location}
END:VEVENT
END:VCALENDAR`
}

const calendarOptions = [
  { id: 'google', label: 'Google', icon: GoogleCalendarIcon },
  { id: 'outlook', label: 'Outlook.com', icon: OutlookCalendarIcon },
  { id: 'apple', label: 'Apple', icon: AppleCalendarIcon },
  { id: 'ical', label: 'iCal', icon: ICalendarIcon },
]

export default function CustomAddToCalendarButton({ data }: { data: ScheduleTourTourType & { prospectUserName: string } }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const tourUnitStr = data.units
    .map(item => {
      if (item.unitName && item.unitId) {
        return `${item.floorPlanName} - ${item.unitName}`
      }
      return `${item.floorPlanName} - Other Unit`
    })
    .join('; ')

  const formattedStartTime = data.startTime
    ? `${momentTimezone(data.startTime).tz(data.timeZone).format('h:mm a, dddd, MMM DD, YYYY')} ${data.timeZoneText}`
    : '-'

  const calendarEvent: CalendarEvent = {
    title: `${data.prospectUserName} tour at ${data.propertyName}`,
    description: `Dear ${data.prospectUserName}, Thank you for scheduling a tour with Tripalink!\n
• Date and time: ${formattedStartTime}\n
• Meet at: ${data.propertyAddress}\n
• Homes to tour:\n
- ${data.propertyName}: ${tourUnitStr}\n
${data.propertyAddress}\n
Our team member will meet you at the property. Please arrive on time and feel free to contact us if you have any questions.`,
    location: data.propertyAddress,
    startTime: data.startTime,
    endTime: moment(data.startTime)
      .add(data.tourAgentTourDuration ? parseInt(data.tourAgentTourDuration, 10) : 30, 'minutes')
      .toISOString(),
    timezone: data.timeZone,
  }

  const handleCalendarClick = (calendarType: string) => {
    Statsig.logEvent('survey_link_to_calendar', window.location.href)

    switch (calendarType) {
      case 'google':
        window.open(generateGoogleCalendarUrl(calendarEvent), '_blank')
        break
      case 'outlook':
        window.open(generateOutlookOnlineUrl(calendarEvent), '_blank')
        break
      case 'apple':
      case 'ical':
        const blob = new Blob([generateIcsFileContent(calendarEvent)], { type: 'text/calendar;charset=utf-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'event.ics'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        break
      default:
        return null
    }
    setIsDropdownOpen(false)
  }

  return (
    <div className="relative">
      <button
        type="button"
        className="w-full bg-[#E74F4F] text-white rounded-xl px-4 py-3 flex items-center justify-center font-semibold"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
          <path
            d="M9 11H7V13H9V11ZM13 11H11V13H13V11ZM17 11H15V13H17V11ZM19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V9H19V20Z"
            fill="white"
          />
        </svg>
        Add to calendar
      </button>

      {isDropdownOpen && (
        <div className="absolute top-full left-0 w-full mt-1 bg-white rounded-xl shadow-lg z-50">
          {calendarOptions.map(({ id, label, icon: Icon }) => (
            <button
              type="button"
              key={id}
              className="w-full text-left px-4 py-3 hover:bg-gray-100 flex items-center"
              onClick={() => handleCalendarClick(id)}
            >
              <Icon className="w-5 h-5 mr-2" />
              {label}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
